/* src/index.css */
@font-face {
  font-family: 'SmileySans-Oblique';
  src: url('./assets/fonts/SmileySans-Oblique.otf') format('otf'),
       url('./assets/fonts/SmileySans-Oblique.woff') format('woff'),
       url('./assets/fonts/SmileySans-Oblique.woff2') format('woff2');
}

body {
  font-family: 'SmileySans-Oblique', sans-serif; /* 将全局字体设置为 MyFont */
}


