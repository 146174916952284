/* 新的内容部分样式 */
.new-section {
    display: flex;
    flex-wrap: wrap; /* 允许项目换行 */
    position: relative;
    top: 5rem;
    width: 100%;
    z-index: 100;
}

.project {
    flex: 1 1 calc(33.333% - 40px); /* 每个项目占据 33.333% 宽度并考虑间距 */
    color: white; /* 字体颜色 */
    text-align: left; 
    flex-direction: column;
    margin: .5em;
}

.img-wrapper {
  position: relative; /* 确保伪元素相对于 .img-wrapper 定位 */
  background-size: cover; /* 确保背景图像覆盖整个容器 */
  overflow: hidden; /* 隐藏溢出的内容 */
}

.img-wrapper::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; /* 隐藏溢出的内容 */
  background-size: cover; /* 确保背景图像覆盖整个容器 */
  background-position: 50%; /* 居中显示背景图像 */
  background-repeat: no-repeat; /* 防止背景图像重复 */
  transition: transform 0.5s ease; /* 添加过渡效果 */
}

.img-wrapper.one::before {
  background-image: url('../images/1.jpg'); /* 设置第一张背景图片 */
}

.img-wrapper.two::before {
  background-image: url('../images/2.jpg'); /* 设置第二张背景图片 */
}

.img-wrapper.three::before {
  background-image: url('../images/3.jpg'); /* 设置第三张背景图片 */
}

.img-wrapper.four::before {
  background-image: url('../images/4.jpg'); /* 设置第一张背景图片 */
}

.img-wrapper.five::before {
  background-image: url('../images/5.jpg'); /* 设置第二张背景图片 */
}

.img-wrapper.six::before {
  background-image: url('../images/6.jpg'); /* 设置第三张背景图片 */
}

.img-wrapper.seven::before {
  background-image: url('../images/7.jpg'); /* 设置第三张背景图片 */
}

.img-wrapper.eight::before {
  background-image: url('../images/8.jpg'); /* 设置第三张背景图片 */
}

.img-wrapper.nine::before {
  background-image: url('../images/9.jpg'); /* 设置第三张背景图片 */
}

.titlepng {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 18vh;
  background-size: contain;
  background-repeat: no-repeat; /* 防止背景图像重复 */
  margin-top: 1rem;
}

.titlepng.one {
  background-image: url('../images/pc1.png'); /* 设置第一张背景图片 */
}

.titlepng2 {
  background-image: url('../images/pc2.png'); /* 设置第二张背景图片 */
}

.titlepng3 {
  background-image: url('../images/pc3.png'); /* 设置第三张背景图片 */
}

.titlepng4 {
  background-image: url('../images/pc4.png'); /* 设置第一张背景图片 */
}

.titlepng5 {
  background-image: url('../images/pc5.png'); /* 设置第二张背景图片 */
}

.titlepng6 {
  background-image: url('../images/pc6.png'); /* 设置第三张背景图片 */
}

.img-wrapper:hover::before {
  transform: scale(1.1); /* 鼠标悬停时放大 */
}

.project h2 {
    padding-top: 0.5em;
    font-size: 0.5em; /* 设置字体大小 */
    margin: 0.55em 0;
    font-weight: normal;
}

.project p {
    font-size: 2em; /* 设置字体大小 */
    margin: 0;
    font-weight: normal;
    color: white; /* 字体颜色 */
}

@keyframes slideIn {
    from {
      transform: translateY(-100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  .new-page-enter {
    animation: slideIn 0.5s forwards;
  }

  /* src/pages/Page4.css */

/* 移动设备样式 */
@media only screen and (max-width: 768px) {
    .new-section {
      display: flex;
      flex-direction: column;
    }
  
    .project {
      position: relative;
      margin-top: 0px;
    }
  
    .img-wrapper {
      width: 100%;
      height: 100%;
      background-size: cover;
    }

    .img-wrapper::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 200px;
      background-size: cover; /* 确保背景图像覆盖整个容器 */
      background-position: 50%; /* 居中显示背景图像 */
      background-repeat: no-repeat; /* 防止背景图像重复 */
      transition: transform 0.5s ease; /* 添加过渡效果 */
    }

    .img-wrapper.one ,
    .img-wrapper.two ,
    .img-wrapper.three ,
    .img-wrapper.four ,
    .img-wrapper.five ,
    .img-wrapper.six ,
    .img-wrapper.seven ,
    .img-wrapper.eight ,
    .img-wrapper.nine {
      width: 100%;
      height: 200px;
    }
    
    .img-wrapper.one{
    margin-top: 0px;
    }

    .titlepng {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      height: 10vh;
      background-size: cover;
      background-repeat: no-repeat; /* 防止背景图像重复 */
      margin-top: 1.5rem;
      margin-bottom: 2rem;
    }

    .project h2 {
      font-size: .7em; /* 调整字体大小 */
      opacity: 1;
      font-family: SmileySans-Oblique;
    }
  
    .project p {
      font-size: 2em; /* 调整字体大小 */
      margin-top: -5px;
      opacity: 1;
    }
  }
  