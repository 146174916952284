/* 自定义水平滑动导航按钮的位置 */
.fp-slidesNav {
    position: absolute;
    top: 50%; /* 将导航按钮垂直居中 */
    left: 50%;
    transform: translate(-50%, -50%); /* 使其在水平和垂直方向上居中 */
    z-index: 100;
    display: flex;
    align-items: center;
  }
  
  .fp-slidesNav ul {
    display: flex;
  }
  
  .fp-slidesNav ul li {
    margin: 0 50px;
  }

  @keyframes slideInFromLeft {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  .page-enter {
    animation: slideInFromLeft 1s ease-out;
    z-index: 5;
  }
  
  .fp-overflow {
    height: 100%;
    width: 100%;
  }