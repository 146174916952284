/* src/pages/Page2.css */
.text-container {
    position: absolute;
    top: 25vh;
    width: 100%;
    height: 100vh;
    perspective: 1000px;
}

.text-front, .text-back {
    position: absolute;
    width: 100%;
    transition: opacity 0.6s, filter 0.6s, transform 0.6s, top 0.6s, left 1.6s, color 0.6s;
    display: flex;
    font-size: 5vw;
    letter-spacing: 0.2rem; /* 增加字间距 */
    text-align: left;
}

.text-front {
    top: -5vh;
    left: 10%;
    z-index: 2;
}

.text-back {
    opacity: 1;
    filter: blur(10px);
    z-index: 1;
    transform: translateZ(-100px);
    top: 10vh;
    color: #002FA7;
    left: 30%;
}

.text-container.hover .text-front {
    opacity: 1;
    filter: blur(10px);
    z-index: 1;
    transform: translateZ(-100px);
    top: 10vh;
    color: #002FA7;
    left: 50%;
}

.text-container.hover .text-back {
    opacity: 1;
    filter: blur(0);
    z-index: 2;
    transform: translateZ(0);
    top: -5vh;
    color: #000000;
    left: 10%;
}

.adjustable-text {
    font-size: 1.5vw;
    color: #666666;
    position: absolute;
    top: 66vh;
    left: 9%;
    padding: 10px;
    opacity: 1;
    transition: opacity 1s;
}

.adjustable-text.en {
    opacity: 0;
}

.text-container.hover .adjustable-text.cn {
    opacity: 0;
}

.text-container.hover .adjustable-text.en {
    opacity: 1;
}

/* 移动设备样式 */
@media only screen and (max-width: 768px) {
    .text-front {
      font-size: 11.5vw; /* 调整字体大小 */
      top: 2vh; /* 调整位置 */
      left: 8%; /* 调整位置 */
      letter-spacing: 0rem; /* 增加字间距 */
    }

    .text-back {
        font-size: 13vw; /* 调整字体大小 */
        top: 10vh; /* 调整位置 */
        left: 10%; /* 调整位置 */
        letter-spacing: 0rem; /* 增加字间距 */
      }
  
    .text-container.hover .text-front {
      top: 28vh;
      left: 10%;
    }
  
    .text-container.hover .text-back {
      top: -5vh;
      left: 8%;
    }
  
    .adjustable-text {
      font-size: 12px; /* 调整字体大小 */
      text-align: center; /* 居中文本 */
      top: 65%;
      right: 30px;
    }
  }