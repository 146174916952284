.c-cover {
    display: flex;
    flex-direction: column;
    position: relative; 
    align-items: center;
    overflow: hidden; /* 隐藏溢出内容 */
    margin: 0; /* 去除外边距 */
    padding: 0; /* 去除内边距 */
    top: 15%;
}

.c-cover__text {
    font-size: 20rem;
    color: black;
    font-weight: normal;
    white-space: nowrap; /* 防止文字换行 */
    overflow: visible; /* 允许内容溢出 */
    transition: transform 2s ease-out; /* 添加过渡效果 */
    z-index: 2;
    position: relative; 
    left: 140px;
    margin: 0; /* 去除外边距 */
    padding: 0; /* 去除内边距 */
}

.c-cover__text--highlight {
    margin-top: -2.6%;
    line-height: 10rem; /* 调整高度 */
    font-size: 4rem;
    color: #ffffff;
    background-color: #002FA7;
    white-space: nowrap; /* 防止文字换行 */
    overflow: visible; /* 允许内容溢出 */
    transition: transform 3s ease-out; /* 添加过渡效果 */
    z-index: 1;
    left: 200px;
    letter-spacing: 2.6rem; /* 增加字间距 */
}

/* 修改 c-text-container 样式 */
.c-text-container {
    position: absolute; /* 绝对定位 */
    display: flex;
    flex-direction: row; /* 使子元素水平排列 */
    justify-content: space-between; /* 在容器中均匀分布 */
    align-items: center;
    width: 100%; /* 占满父容器宽度 */
    overflow: hidden; /* 隐藏溢出内容 */
    top: 87%; /* 距离顶部10% */
}

/* 修改 c-text-block 样式 */
.c-text-block {
    color: #666666; /* 设置字体颜色 */
    font-size: 1.5rem; /* 根据需要调整字体大小 */
    z-index: 1; /* 确保文字在最前面 */
    max-width: 100%; /* 限制宽度为视口宽度 */
    overflow: hidden; /* 隐藏溢出内容 */
    white-space: nowrap; /* 防止文字换行 */
    text-overflow: ellipsis; /* 添加省略号处理溢出文本 */
    padding: 0.5rem; /* 添加一些内边距以确保内容不紧贴边缘 */
}

.c-line {
    position: absolute;
    width: 100%;
    height: .2rem;
    background-color: #002FA7;
}

.c-line.top  {
    top: 86%; /* 将其定位到容器顶部 */
}

.c-line.bottom  {
    top: 95%; /* 将其定位到容器顶部 */
}

@media only screen and (max-width: 768px) {
    .c-cover__text {
      animation: marquee 30s linear infinite;
      left: 0;
      white-space: nowrap;
    }

    .c-cover__text--highlight {
      animation: marquee 40s linear infinite;
      left: 0;
      white-space: nowrap;
      letter-spacing: 0.4rem; /* 增加字间距 */
    }

  .c-cover__text--highlight {
    margin-top: -14.9%;
  }
  
  .c-text-block {
    color: #666666; /* 设置字体颜色 */
    font-size: 1rem; /* 根据需要调整字体大小 */
    z-index: 1; /* 确保文字在最前面 */
    max-width: 100%; /* 限制宽度为视口宽度 */
    overflow: hidden; /* 隐藏溢出内容 */
    white-space: nowrap; /* 防止文字换行 */
}

.c-line {
    position: absolute;
    width: 100%;
    height: .15rem;
    background-color: #002FA7;
}

.c-line.bottom  {
    top: 94%; /* 将其定位到容器顶部 */
    height: .13rem;
}
}
  @keyframes marquee {
    from {
      transform: translateX(50%);
    }
    to {
      transform: translateX(-50%);
    }
  }
