/* src/components/Navbar.css */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10000; /* 确保导航栏在最前面 */
  transition: background-color 0.5s ease; /* 添加颜色过渡效果 */
}

.navbar-logo {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* 左对齐导航链接 */
  background-color: white; /* 添加白色背景 */
  padding: 10px 120px; /* 添加内边距，以确保 logo 与背景边缘之间有一些空间 */
  padding-left: 30px;
}

.navbar-logo img {
  height: 60px; /* 调整logo的高度 */
}

.navbar-content {
  display: flex;
  justify-content: flex-start; /* 左对齐导航链接 */
  width: 100%;
  opacity: 1;
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.navbar-content.hidden {
  opacity: 0;
  transform: translateY(20px);
}

.navbar-content.show {
  opacity: 1;
  transform: translateY(0);
}

.navbar-content a {
  color: white; /* 统一字体颜色 */
  text-decoration: none;
  padding: 0 50px;
  font-size: 1.7rem;
  transition: color 0.5s ease; /* 添加颜色过渡效果 */
}

.back-button {
  color: white; /* 统一字体颜色 */
  text-decoration: none;
  padding: 0 50px;
  font-size: 1.7rem;
  transition: color 0.5s ease; /* 添加颜色过渡效果 */
}

.navbar-content a:hover,
.back-button:hover {
  color: white;
  transition: color 0.5s ease; /* 添加颜色过渡效果 */
  font-family: 'SmileySans-Oblique', sans-serif;
}

.back-button {
  background: none;
  border: none;
  font-size: 1.7rem;
  cursor: pointer;
  padding: 0 50px;
  transition: color 0.5s ease;
  font-family: 'SmileySans-Oblique', sans-serif;
}

/* 其他样式保持不变 */

/* 响应式设计 */
@media only screen and (max-width: 768px) {
  .navbar {
    flex-direction: column;
    height: auto;
  }
  
  .navbar-logo {
    padding:10px;
  }
  
  .navbar-logo img {
    height: 40px; /* 调整logo的高度 */
  }

  .navbar-content {
    display: none;
    flex-direction: column;
    align-items: center;
  }

  .navbar-content.show {
    display: flex;
  }
  
  .menu-button {
    display: block;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    margin: 10px;
  }
}