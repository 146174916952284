/* src/pages/ProjectPage.css */
.project-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: fixed;
    overflow-y: auto; /* 允许垂直滚动 */
    background-color: #002FA7; /* 背景颜色，可以根据需要调整 */
    -ms-overflow-style: none;  /* IE 和 Edge 浏览器 */
    scrollbar-width: none;     /* Firefox 浏览器 */
  }

  .project-page img {
    width: 100%; /* 图片宽度为100% */
    height: auto; /* 高度自动调整，保持比例 */
    padding: 0 8vw;
    box-sizing: border-box;
  }
  
  .project-page h1 {
    margin-top: 20px; /* 标题的顶部边距 */
  }
  
  .project-page button {
    margin-top: 2px; /* 按钮的顶部边距 */
    padding: 0px 2px; /* 按钮的内边距 */
    font-size: 16px; /* 按钮的字体大小 */
    cursor: pointer; /* 鼠标悬停时显示指针 */
    background-color: #007bff; /* 按钮背景颜色 */
    color: white; /* 按钮文字颜色 */
    border: none; /* 去除按钮边框 */
    border-radius: 5px; /* 按钮圆角 */
    display: none; /* 隐藏 Back 按钮 */
  }
  
  .project-page button:hover {
    background-color: #0056b3; /* 按钮悬停时背景颜色 */
  }

  .img-wrapper {
    width: 100%; /* 你可以根据需要调整宽度 */
    height: 300px; /* 设置固定高度，或者根据需要调整 */
    display: flex;
    justify-content: center;
    align-items: center;
    }
    
    .img-wrapper img {
    width: 100%; /* 保持图像宽度适应容器 */
    height: auto; /* 保持图像高度自适应，防止变形 */
    }

    .textpage {
      word-wrap: break-word;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      background-color: #002FA7;
      color: white;
      padding: 1vw 8vw;
      position: relative; /* 确保元素参与堆叠上下文 */
    }

    .textpage1 {
      padding: 0;
  }

    .textbackground1 {
      background-size: cover; /* 使图片覆盖整个div */
      background-position: center; /* 图片居中 */
      background-repeat: no-repeat; /* 防止图片重复 */
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100vh;
      margin-top: 5vh;
  }

    .textpage3 {
      background-size: cover; /* 使图片覆盖整个div */
      background-position: center; /* 图片居中 */
      background-repeat: no-repeat; /* 防止图片重复 */
  }

    .textpage2-text1 {
      display: flex;
      font-size: 5vw;
      width: 100%;
      box-sizing: border-box;
      padding-right: 55vw;
      margin: 0;
    }

    .text2row {
      display: flex;
      flex-direction: row;
    }

    .text2title1 {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      padding-left: 20vw; /* 根据需要调整内边距 */
      padding-right: 6vw;
      margin: 1vw;
    }

    .text2title2 {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      margin: 1vw;
    }

    .textpage2-text2,.textpage2-text3,.textpage2-text4,.textpage2-text5 {
      font-size: 2vw;
      line-height: 1.7;
      margin: 1vw;
    }

    .textpage2-text6,.textpage2-text7,.textpage2-text8,.textpage2-text9 {
      font-size: 1.5vw;
      margin: 1vw;
      line-height: 1.7;
      white-space: nowrap;
    }

    .textpage3-text1,.textpage4-text1,.textpage5-text1,.textpage6-text1,.textpage7-text1,.textpage8-text1,.textpage9-text1,.textpage10-text1,.textpage11-text1 {
      font-size: 5vw;
      margin: 0;
    }

    .textpage3-text2,.textpage4-text2 {
      font-size: 2vw;
      padding-left: 35vw; /* 根据需要调整内边距 */
      padding-right: 7vw;
      margin: 1vw;
      text-align: justify;
      line-height: 1.7;
      word-break: break-all;
      word-wrap: break-word;
    }

    .textpage5-text2,.textpage6-text2,.textpage7-text2,.textpage8-text2,.textpage9-text2,.textpage10-text2,.textpage11-text2 {
      font-size: 2vw;
      margin: 0vw;
      text-align: justify;
      line-height: 1.7;
      padding-right: 8vw;
      width: 28vw;
      height: 24vw;
      padding-top: 1vw;
    }

    .textR {
      flex-direction: column;
    }

    .introduce {
      flex-direction: row;
      display: flex;
    }

    .images {
      background-size: cover; /* 使图片覆盖整个div */
      background-position: center; /* 图片居中 */
      background-repeat: no-repeat; /* 防止图片重复 */
      width: 100vw;
      padding-left: 20vw; /* 根据需要调整内边距 */
  }
    
    .images6 {
      background-size: cover; /* 使图片覆盖整个div */
      background-position: center; /* 图片居中 */
      background-repeat: no-repeat; /* 防止图片重复 */
      width: 100vw;
      padding-left: 20vw; /* 根据需要调整内边距 */
  }

    .images7 {
      background-size: cover; /* 使图片覆盖整个div */
      background-position: center; /* 图片居中 */
      background-repeat: no-repeat; /* 防止图片重复 */
      width: 100vw;
      padding-left: 20vw; /* 根据需要调整内边距 */
  }

    .images8 {
      background-size: cover; /* 使图片覆盖整个div */
      background-position: center; /* 图片居中 */
      background-repeat: no-repeat; /* 防止图片重复 */
      width: 100vw;
      padding-left: 20vw; /* 根据需要调整内边距 */
  }

    .c-images {
      background-color: #002FA7;
      max-width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;
      margin: 0.2vh 0;
  }

    .c-images2 {
      background-color: #002FA7;
      max-width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;
  }

  .c-logo {
    display: block;
    width: 100%;
    height: auto;
    max-width: 40%;
}

    video {
      width: 100vw;
      padding: 0 8vw;
      box-sizing: border-box;
    }
    
    /* 移动设备样式 */
    @media only screen and (max-width: 768px) {
      .project-page button {
        display: none; /* 隐藏 Back 按钮 */
      }

      .text2row {
        display: flex;
        flex-direction: column;
      }

      .textpage2-text1 {
        display: flex;
        font-size: 10vw;
        width: 100%;
        box-sizing: border-box;
        padding-right: 0;
        margin: 0;
      }

      .text2title1 {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding-left: 0vw; /* 根据需要调整内边距 */
        padding-right: 0vw;
        margin: 1vw;
      }

      .textpage2-text2,.textpage2-text3,.textpage2-text4,.textpage2-text5 {
        font-size: 5vw;
        line-height: 1.7;
        margin: 0vw;
        margin-top: 4vw;
      }

      .textpage2-text6,.textpage2-text7,.textpage2-text8,.textpage2-text9 {
        font-size: 5vw;
        margin: 0vw;
        line-height: 1.7;
        white-space: nowrap;
        margin-top: 4vw;
      }

      .textpage3-text1,.textpage4-text1,.textpage5-text1,.textpage6-text1,.textpage7-text1,.textpage8-text1 ,.textpage9-text1,.textpage10-text1,.textpage11-text1 {
        font-size: 10vw;
        margin: 0;
      }

      .textpage3-text2,.textpage4-text2 {
        font-size: 5vw;
        padding-left: 0vw; /* 根据需要调整内边距 */
        padding-right: 0vw;
        margin: 0vw;
        text-align: justify;
        line-height: 1.7;
        margin-top: 4vw;
      }

      .textpage5-text2,.textpage6-text2,.textpage7-text2,.textpage8-text2,.textpage9-text2,.textpage10-text2,.textpage11-text2 {
        font-size: 5vw;
        margin: 0vw;
        text-align: justify;
        line-height: 1.7;
        padding-right: 0vw;
        width: 100%;
        height: 100%;
        margin-top: 4vw;
      }

      .introduce {
        flex-direction: column;
        display: flex;
      }

      .textpage {
        word-wrap: break-word;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        background-color: #002FA7;
        color: white;
        padding: 5vw 5vw;
        position: relative; /* 确保元素参与堆叠上下文 */
      }

      .textpage1 {
        padding: 0vw 0vw;
      }
  
      .images {
        width: 100%;
        height: 30vh;
        margin-top: 2vh;
        padding-left: 0vw;
    }
      
      .charu {
        display: none;
    }

      .c-logo {
        max-width: 80%;
      }

      .c-images2 {
        margin-top: 60px;
        background-color: #002FA7;
        max-width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
    }

    .project-page img {
      padding: 0 5vw;
      box-sizing: border-box;
    }

    video {
      padding: 0 5vw;
      box-sizing: border-box;
    }

    }
