.new-content-background {
    background: 
        radial-gradient(circle at 10% 20%, rgba(39, 95, 172, 1), rgba(39, 95, 172, 0) 50%), 
        radial-gradient(circle at 100% 100%, rgba(39, 95, 172, 1), rgba(39, 95, 172, 0) 50%),   
        #053A95; /* 添加底色 */
    background-blend-mode: screen;
    width: 100%;
    height: 100vh;
    top: 0;
    z-index: 1; /* 确保足够高的 z-index 值 */
    position: absolute; /* 绝对定位 */
}

.new-content-background-logo {
    background:  url('./path/to/your/image.png');
    width: 100%;
    height: 100vh;
    top: 20vh;
    z-index: 2; /* 确保足够高的 z-index 值 */
    position: absolute; /* 绝对定位 */
    background-repeat:no-repeat;
    background-size: 100%;
    background-position: center;
    opacity: 0;
}

.fp-slides{
    z-index: 3; /* 确保足够高的 z-index 值 */
}

.new-content {
    display: flex;
    flex-direction: row; /* 水平排列子页面 */
    overflow: hidden; /* 隐藏溢出的内容 */
    color: white; /* 设置文本颜色 */
    height: 100vh; /* 设置高度为视口高度 */
    white-space: nowrap; /* 防止文本换行 */
    font-weight: normal; /* 设置字体粗细 */
    position: relative; /* 绝对定位 */
    width:100vw ;
    z-index: 10; /* 确保足够高的 z-index 值 */
}

.new-content-text {
    display: flex; /* 设置为弹性盒模型，使子元素可以灵活排列 */
    position: absolute; /* 添加 position 属性 */
    top: 200px;
    left: 240px; /* 根据需要调整 left 属性 */
    z-index: 5; /* 确保导航栏位于较高层 */
}

.new-content-text h1 {
    font-size: 7.5rem; /* 设置字体大小 */
    margin: 0; /* 去除默认外边距 */
    letter-spacing: 0.2vw; /* 调整字间距 */
    font-weight: normal;
}

.new-content-text h2 {
    font-size: 3.6rem; /* 设置字体大小 */
    margin: 0; /* 去除默认外边距 */
    margin-left: 1.5vw; /* 调整 h2 到 h1 的左间距 */
}

.new-content-text.animate {
    animation: slide-in-left-to-right 2s forwards; /* 应用动画 */
}

.new-content-steps {
    display: flex;
    width: 100%;
    position: absolute; /* 设置绝对定位 */
    margin-top: 20vh; /* 使用相对单位设置下边距 */
    z-index: 1000;
}

.new-content-steps.animate span {
    opacity: 1; /* 初始透明度 */
    animation: slide-in-step 0.5s forwards; /* 应用动画 */
}

.new-content-steps span {
    position: relative;
    cursor: pointer; /* 鼠标悬停时显示指针 */
    margin: 0 2.2vw; /* 设置每个 span 元素左右间距 */
    font-size: 3.6rem; /* 设置字体大小 */
}

.new-content-steps span:first-child {
    margin-left: 0; /* 去掉第一个 span 元素的左边距 */
}

.dropdown-content {
    visibility: hidden; /* 使用 visibility 而不是 display */
    position: absolute;
    z-index: 1000;
    top: 100%; /* 放在 span 元素下面 */
    left: 0; /* 左对齐 */
    opacity: 0;
    transform: translateY(-10px); /* 初始位置稍微在上方 */
    transition: transform 0.3s ease-out, opacity 0.3s ease-out, visibility 0.3s; /* 添加动画效果 */
}

.dropdown:hover .dropdown-content {
    visibility: visible; /* 使用 visibility */
    opacity: 1; /* 设置不透明度 */
    transform: translateY(0); /* 移动到最终位置 */
}

.dropdown-content p {
    color: white;
    padding: 0px 0vh;
    text-decoration: none;
    display: block;
    font-size: 1.5rem; /* 设置字体大小 */
}

.new-content-image {
    opacity: 0; /* 初始状态为透明 */
    transition: opacity 0.5s ease-in; /* 渐隐显示动画 */
    position: fixed; /* 绝对定位 */
    z-index: 11; /* 确保足够高的 z-index 值 */
    top: 45%; /* 距顶部距离 */
    left: 140%; /* 距右侧距离 */
}

.new-content-image img {
    width: 18vh; /* 设置图片宽度 */
    height: auto; /* 根据宽度自动调整高度 */
}

.new-content-steps.animate ~ .new-content-image {
    animation: fadeIn 0.5s ease-in forwards;
    animation-delay: 1.5s; /* 根据 new-content-steps 动画的持续时间调整此值 */
}

.page3-logo {
    opacity: 0;
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}

/* 移动设备样式 */
@media only screen and (max-width: 768px) {
    
    .new-content-text {
      flex-wrap: wrap; /* 允许换行 */
      top: 120px;
      left: 30px;
    }
  
    .new-content-background-logo {
        opacity: 0.2;
    }

    .new-content-text h1 {
      font-size: 2.8rem;
    }
  
    .new-content-text h2 {
      font-size: 1.5rem;
    }
  
    .new-content-steps {
        margin-top: 20vh;
    }
  
    .new-content-steps span {
      flex-direction: column; /* 垂直方向排列 */
      align-items: center; /* 居中对齐 */
      font-size: 1.4rem;
      margin: 0vh 0.8vh;
      top: 5px;
    }
  
    .dropdown-content {
        transform: translateY(0);
        visibility: visible;
      opacity: 0;
      position: relative;
      top: 0px;
      width: 100%;
      transition: transform 0.8s ease-out, opacity 0.8s ease-out, visibility 0.8s; /* 添加动画效果 */
    }

    .page3-logo {
        opacity: 0.6;
        position: absolute;
        text-align: center; /* 居中文本 */
        top: 160%;
        left: 34%;
    }

    .dropdown:hover .dropdown-content {
    }
  
    .dropdown-content p {
      font-size: 1rem;
    }     
  }